<template>
  <div>
    <CCard class="col-lg-6 col-sm-12">
      <CCardHeader>
        Traffic Log
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="position-relative">
              <CDataTable
                hover
                small
                fixed
                table-filter
                sorter
                items-per-page-select
                :items-per-page="15"
                :fields="logListFields"
                :items="logListItem"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >
                <template #time="{item}">
                  <td class="py-2">
                    {{item.log_time | dateFormat}}
                  </td>
                </template>
                <template #node="{item}">
                  <td class="py-2">
                    {{item.node.name}}
                  </td>
                </template>
              </CDataTable>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="1"
                v-if="logLoading"
              >
                <h1 class="d-inline">Loading... </h1>
                <CSpinner size="2xl" color="success" />
              </CElementCover>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: "TrafficLog",
  data() {
    const logListFields = [
      { key: "time", label: "Time" },
      { key: "node", label: "Node Name" },
      { key: "traffic", label: "Used Traffic" },
      { key: "u", label: "Upload" },
      { key: "d", label: "Download" }

    ];
    return {
      logLoading: true,
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      logListFields,
      logListItem: [],
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/account/traffic-log/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.logListItem = response.data.logs;
            this.logLoading = false;
          }
        });
    }
  }
};
</script>